import config from '@/helpers/config';
import { authHeaderAsync } from '@/helpers';
import axios from 'axios'
import handleApiError from '@/helpers/handleApiError.helper'

export const boxesService = {
  getAll,
  getById,
  ConfirmById,
  createBox,
  deleteBox,
  getBoxUnits,
  addUnitToBox,
  removeUnitFromBox,
  print
};

async function getAll(search = '', pagesize = 10, page = 1) {
  return axios.get(`${config.apiUrl}/manufacturing/boxes?search=${search}&pagesize=${pagesize}&page=${page}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function ConfirmById(id) {
  return axios.post(`${config.apiUrl}/manufacturing/boxes/${id}/confirm`, {}, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function getBoxUnits(id, pagesize = 10, page = 1) {
  return axios.get(`${config.apiUrl}/manufacturing/boxes/${id}/units?pagesize=${pagesize}&page=${page}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function addUnitToBox(id, articlenumber, serialnumber) {
  return axios.post(`${config.apiUrl}/manufacturing/boxes/${id}/units`, { articlenumber: articlenumber, serialnumber: serialnumber}, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function createBox() {
  return axios.post(`${config.apiUrl}/manufacturing/boxes`, {}, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function deleteBox(boxid) {
  return axios.delete(`${config.apiUrl}/manufacturing/boxes/${boxid}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function removeUnitFromBox(boxid, unitid) {
  return axios.delete(`${config.apiUrl}/manufacturing/boxes/${boxid}/units/${unitid}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function getById(id) {
  return axios.get(`${config.apiUrl}/manufacturing/boxes/${id}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function print(id, settings) {
  return axios.post(`${config.apiUrl}/manufacturing/boxes/${id}/print`, settings, { headers: await authHeaderAsync(), responseType: 'arraybuffer' })
    .then(response => { return response.data })
    .catch(handleApiError)
}