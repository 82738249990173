import config from '@/helpers/config';
import { authHeaderAsync } from '@/helpers';
import axios from 'axios'
import handleApiError from '@/helpers/handleApiError.helper'

export const testsService = {
  getAll,
  getById,
  getAllResults,
  getResultById,
  getAllWorkers,
  getWorkerById,
  getAllUploads,
  getUploadById,
  getWorkerLogs
};

async function getAll(search = '', pagesize = 10, page = 1) {
  return axios.get(`${config.apiUrl}/manufacturing/test/pipelines?search=${search}&pagesize=${pagesize}&page=${page}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function getById(id) {
  return axios.get(`${config.apiUrl}/manufacturing/test/pipelines/${id}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function getAllResults(id, search = '', pagesize = 10, page = 1) {
    return axios.get(`${config.apiUrl}/manufacturing/test/pipelines/${id}/results?search=${search}&pagesize=${pagesize}&page=${page}`, { headers: await authHeaderAsync() })
      .then(response => { return response.data })
      .catch(handleApiError)
}

async function getResultById(pipelineid, resultid) {
  return axios.get(`${config.apiUrl}/manufacturing/test/pipelines/${pipelineid}/results/${resultid}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function getAllWorkers(pipelineid, search = '', pagesize = 10, page = 1) {
  return axios.get(`${config.apiUrl}/manufacturing/test/pipelines/${pipelineid}/workers?search=${search}&pagesize=${pagesize}&page=${page}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function getWorkerById(pipelineid, workerid) {
  return axios.get(`${config.apiUrl}/manufacturing/test/pipelines/${pipelineid}/workers/${workerid}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function getAllUploads(pipelineid, search = '', pagesize = 10, page = 1) {
  return axios.get(`${config.apiUrl}/manufacturing/test/pipelines/${pipelineid}/uploads?search=${search}&pagesize=${pagesize}&page=${page}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function getUploadById(pipelineid, uploadid) {
  return axios.get(`${config.apiUrl}/manufacturing/test/pipelines/${pipelineid}/uploads/${uploadid}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function getWorkerLogs(pipelineid, workerid, search = '', pagesize = 10, page = 1) {
  return axios.get(`${config.apiUrl}/manufacturing/test/pipelines/${pipelineid}/workers/${workerid}/logs?search=${search}&pagesize=${pagesize}&page=${page}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}
