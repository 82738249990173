import config from '@/helpers/config';
import { authHeaderAsync } from '@/helpers';
import axios from 'axios'
import handleApiError from '@/helpers/handleApiError.helper'

export const systemreferencesService = {
  getAll,
  getById,
};

async function getAll(search = '', pagesize = 10, page = 1) {
  return axios.get(`${config.apiUrl}/manufacturing/settings/boxes/systemreferences?pagesize=${pagesize}&page=${page}&search=${search}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function getById(id) {
  return axios.get(`${config.apiUrl}/manufacturing/settings/boxes/systemreferences/${id}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}
