import router from "@/router"

function handleApiError(error) {
  let msg = ''

  if(!error.response) {
    return Promise.reject('Cannot access API. Check your internet connection')
  }

  switch(error.response.status) {
    case 400: {
      msg = error.response.data;
      break 
    }
    case 401: { router.push('/forbidden'); break }
    case 403: { router.push('/forbidden'); break }
    case 406: { msg = error.response.data; break }
    default: { msg = error.message; break }
  }

  return Promise.reject(msg)
}

export default handleApiError