import config from '@/helpers/config';
import { authHeaderAsync } from '@/helpers';
import axios from 'axios'
import handleApiError from '@/helpers/handleApiError.helper'

export const printService = {
  getAll,
  getById,
  createTemplate,
  updateTemplate,
  deleteTemplate,
  print,
};

async function getAll(search = '', pagesize = 10, page = 1) {
  return axios.get(`${config.apiUrl}/manufacturing/settings/boxes/print/templates?pagesize=${pagesize}&page=${page}&search=${search}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function getById(id) {
  return axios.get(`${config.apiUrl}/manufacturing/settings/boxes/print/templates/${id}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function createTemplate(post) {
  return axios.post(`${config.apiUrl}/manufacturing/settings/boxes/print/templates`, post, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function updateTemplate(id, post) {
  return axios.post(`${config.apiUrl}/manufacturing/settings/boxes/print/templates/${id}`, post, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function deleteTemplate(id) {
  return axios.delete(`${config.apiUrl}/manufacturing/settings/boxes/print/templates/${id}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function print(settings) {
  return axios.post(`${config.apiUrl}/manufacturing/settings/boxes/print/templates/test`, settings, { headers: await authHeaderAsync(), responseType: 'arraybuffer' })
    .then(response => { return response.data })
    .catch(handleApiError)
}