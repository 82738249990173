import config from '@/helpers/config';
import { authHeaderAsync } from '@/helpers';
import axios from 'axios'
import handleApiError from '@/helpers/handleApiError.helper'

export const productsSettingsService = {
  getAll,
  getById,
  createProduct,
  updateProduct,
  deleteProduct,
  getProductValidationsById,
  addProductValidation,
  removeProductValidation,
};

async function getAll(search, pagesize = 10, page = 1) {
  return axios.get(`${config.apiUrl}/manufacturing/settings/boxes/products?pagesize=${pagesize}&page=${page}&search=${search}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function getById(id) {
  return axios.get(`${config.apiUrl}/manufacturing/settings/boxes/products/${id}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function createProduct(post) {
  return axios.post(`${config.apiUrl}/manufacturing/settings/boxes/products`, post, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function updateProduct(productid, post) {
  return axios.post(`${config.apiUrl}/manufacturing/settings/boxes/products/${productid}`, post, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function deleteProduct(productid) {
  return axios.delete(`${config.apiUrl}/manufacturing/settings/boxes/products/${productid}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function getProductValidationsById(id) {
  return axios.get(`${config.apiUrl}/manufacturing/settings/boxes/products/${id}/validations`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function addProductValidation(productid, validationId) {
  return axios.post(`${config.apiUrl}/manufacturing/settings/boxes/products/${productid}/validations`, { validationid: validationId}, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}

async function removeProductValidation(productid, productvalidationid) {
  return axios.delete(`${config.apiUrl}/manufacturing/settings/boxes/products/${productid}/validations/${productvalidationid}`, { headers: await authHeaderAsync() })
    .then(response => { return response.data })
    .catch(handleApiError)
}